<template functional>
  <div class="bg-white shadow rounded p-4 mb-4" v-if="props.relation">
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.email">
      <div class="coord">{{ parent.$t('relation_coordinates_email') }}</div>
      <div class="col-span-2 font-medium">
        <a :href="'mailto:' + props.relation.email" target="_blank">{{
          props.relation.email
        }}</a>
      </div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.telephoneMobile">
      <div class="coord">
        {{ parent.$t('relation_coordinates_telephone_mobile_abbrev') }}
      </div>
      <div class="col-span-2 font-medium">
        <a :href="'tel:' + telephoneMobile">{{
          props.relation.telephoneMobile
        }}</a>
        &#32;
        <a :href="'sms:' + telephoneMobile">{{
          parent.$t('relation_coordinates_telephone_mobile_sms')
        }}</a>
      </div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.telephone">
      <div class="coord">
        {{ parent.$t('relation_coordinates_telephone_abbrev') }}
      </div>
      <div class="col-span-2 font-medium">
        <a :href="'tel:' + props.relation.telephone">{{
          props.relation.telephone
        }}</a>
      </div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.telephoneWork">
      <div class="coord">
        {{ parent.$t('relation_coordinates_telephone_work_abbrev') }}
      </div>
      <div class="col-span-2 font-medium">
        <a :href="'tel:' + props.relation.telephoneWork">{{
          props.relation.telephoneWork
        }}</a>
      </div>
    </div>
    <div class="">
      <router-link
        :to="{
          name: 'relation-coordinates',
          params: { bid, rid: props.relation.rid }
        }"
      >
        <sqr-button
          icon="edit"
          :label="parent.$t('relation_coordinates_edit')"
          color="white"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import SqrButton from '@/sqrd-ui/SqrButton';
export default {
  name: 'RelationCoordinatePart',
  components: { SqrButton },
  props: {
    relation: { type: Object, default: () => ({}) }
  },
  computed: {
    telephoneMobile() {
      return (
        (this.props.relation && this.props.relation.telephoneMobile) ||
        ''
      ).replace(/[^0-9+]/g, '');
    }
  }
};
</script>
