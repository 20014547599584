import { render, staticRenderFns } from "./RelationManagementPart.vue?vue&type=template&id=f0d2703c&scoped=true&functional=true"
import script from "./RelationManagementPart.vue?vue&type=script&lang=js"
export * from "./RelationManagementPart.vue?vue&type=script&lang=js"
import style0 from "./RelationManagementPart.vue?vue&type=style&index=0&id=f0d2703c&prod&lang=scss&scoped=true"
import style1 from "./RelationManagementPart.vue?vue&type=style&index=1&id=f0d2703c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "f0d2703c",
  null
  
)

export default component.exports