<template>
  <div class="field hover:bg-gray-100 -m-2 p-2">
    <label class="label" v-if="relation.address">{{
      $t('relation_address')
    }}</label>
    <div class="control" v-if="relation.address">
      <div
        v-if="relation.address.streetAddress || relation.address.streetNumber"
      >
        {{ relation.address.streetAddress }} {{ relation.address.streetNumber }}
      </div>
      <div
        v-if="
          relation.address.postalCode ||
            relation.address.addressLocality ||
            relation.address.addressRegion
        "
      >
        {{ relation.address.postalCode }}
        {{ relation.address.addressLocality }}
        {{ relation.address.addressRegion }}
      </div>
      <div v-if="relation.address.addressCountry">
        {{ relation.address.addressCountry }}
      </div>
    </div>
    <div class="buttons">
      <a
        class="button is-white"
        :href="
          'https://www.google.com/maps/search/?api=1&query=' +
            relation.address.description
        "
        target="_blank"
        v-if="relation.address"
      >
        <span class="icon">
          <fa :icon="['fal', 'map-marker-alt']" />
        </span>
        <span>{{ $t('relation_address_map') }}</span>
      </a>
      <sqr-router-link
        icon="edit"
        label="relation_address_edit"
        to-name="relation-address"
        color="white"
        :to-bid="bid"
        :to-id="id"
      />
      <sqr-button
        v-if="relation.address"
        icon="envelope"
        label="relation_address_envelope_c4"
        color="white"
        @click="envelopeC4()"
      />
      <sqr-button
        v-if="relation.address"
        icon="envelope"
        label="relation_address_envelope_c5"
        color="white"
        @click="envelopeC5()"
      />
      <sqr-button
        v-if="relation.address"
        icon="envelope"
        label="relation_address_envelope_c56"
        color="white"
        @click="envelopeC56()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pdfMake from 'pdfmake';
const pdfFonts = require('../pdfs/vfs_fonts.js');
pdfMake.fonts = {
  Helvetica: {
    normal: 'Helvetica-Regular.ttf',
    bold: 'Helvetica-Bold.ttf'
    // italics: 'fontFile3.ttf',
    //  bolditalics: 'fontFile4.ttf'
  }
};
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function cm(value) {
  return Math.floor(value * 28.345);
}

export default {
  name: 'RelationAddressPart',
  props: {
    bid: String,
    id: String,
    relation: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    addressBroker() {
      if (!this.broker) return '';
      const broker = this.broker;
      return [
        broker.name,
        [broker.address.streetAddress, broker.address.streetNumber]
          .filter(v => Boolean(v))
          .join(' '),
        broker.address.postOfficeBoxNumber,
        [broker.address.postalCode, broker.address.addressLocality]
          .filter(v => Boolean(v))
          .join(' ')
      ]
        .filter(v => Boolean(v))
        .join('\n');
    },
    address() {
      const rel = this.relation;
      const adr = this.relation.address;
      return [
        rel.company,
        rel.civility,
        [rel.givenName, rel.familyName].filter(v => Boolean(v)).join(' '),
        [adr.streetAddress, adr.streetNumber].filter(v => Boolean(v)).join(' '),
        [adr.postalCode, adr.addressLocality, adr.addressRegion]
          .filter(v => Boolean(v))
          .join(' '),
        adr.addressCountry
      ]
        .filter(v => Boolean(v))
        .join('\n');
    }
  },
  methods: {
    envelopeC4() {
      // 229 x 324 mm
      const content = {
        pageSize: 'A4',
        // pageOrientation: 'portrait',
        pageMargins: [10, 10, 10, 10],
        content: [
          {
            text: this.addressBroker,
            fontSize: 12,
            absolutePosition: { x: cm(1.2), y: cm(26) },
            width: cm(12),
            height: cm(3)
          },
          {
            text: this.address,
            fontSize: 14,
            absolutePosition: { x: cm(10), y: cm(7) },
            width: cm(7.5),
            height: cm(6)
          }
        ],
        defaultStyle: {
          fontSize: 14,
          lineHeight: 1.2,
          font: 'Helvetica'
        }
      };
      pdfMake.createPdf(content).print();
    },
    envelopeC5() {
      const content = {
        pageSize: 'C5', // 162 x 229 mm
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 10],
        content: [
          {
            text: this.addressBroker,
            fontSize: 12,
            absolutePosition: { x: cm(1.2), y: cm(1.2) },
            width: cm(12),
            height: cm(3)
          },
          {
            text: this.address,
            fontSize: 14,
            absolutePosition: { x: cm(11), y: cm(9) },
            width: cm(9.7),
            height: cm(6)
          }
        ],
        defaultStyle: {
          fontSize: 14,
          lineHeight: 1.2,
          font: 'Helvetica'
        }
      };
      pdfMake.createPdf(content).print();
    },
    envelopeC56() {
      const content = {
        pageSize: { width: cm(11.4), height: cm(22.9) }, // 114 x 229 mm
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 10],
        content: [
          {
            text: this.addressBroker,
            fontSize: 12,
            absolutePosition: { x: cm(1.2), y: cm(1.2) },
            width: cm(12),
            height: cm(3)
          },
          {
            text: this.address,
            fontSize: 14,
            absolutePosition: { x: cm(4), y: cm(9) },
            width: cm(9.7),
            height: cm(6)
          }
        ],
        defaultStyle: {
          fontSize: 14,
          lineHeight: 1.2,
          font: 'Helvetica'
        }
      };
      pdfMake.createPdf(content).print();
    }
  }
};
</script>
