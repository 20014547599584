<template>
  <main class="">
    <section class="section" v-if="!loadError && relation">
      <div class="">
        <div class="columns">
          <div class="column is-4">
            <relation-name-part :bid="bid" :rid="rid" :relation="relation" />

            <relation-management-part :bid="bid" :rid="rid" :relation="relation" />

            <router-link
              :to="{ name: 'inquiry-start', params: { bid }, query: { rid } }"
            >
              <sqr-button
                icon="plus"
                label="Demander des offres"
                color="blue"
                class="w-full text-left"
              />
            </router-link>
          </div>
          <div class="column is-4">
            <relation-coordinates-part
              :bid="bid"
              :rid="rid"
              :relation="relation"
            />
            <relation-address-part :relation="relation" />
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label">{{ $t('relation_notes') }}</label>
              <div style="white-space: pre-wrap;">{{ relation.notes }}</div>
              <sqr-router-link
                icon="edit"
                label="relation_notes_edit"
                to-name="relation-notes"
                :to-rid="rid"
                color="white"
                size="normal"
                is-fullwidth
              />
            </div>
            <div class="field">
              <div class="field" v-if="relation.bankAccount">
                <label class="label">{{ $t('relation_bank_account') }}</label>
                <div class="control" v-if="relation.bankAccount.company">
                  {{ relation.bankAccount.company }}
                </div>
                <div class="control" v-if="relation.bankAccount.iban">
                  {{ relation.bankAccount.iban }}
                </div>
                <div class="control" v-if="relation.bankAccount.note">
                  {{ relation.bankAccount.note }}
                </div>
              </div>
              <div class="control">
                <sqr-router-link
                  icon="edit"
                  label="relation_bank_account_edit"
                  to-name="relation-bank-account"
                  :to-rid="rid"
                  color="white"
                  size="normal"
                  is-fullwidth
                />
              </div>
            </div>
            <div class="field" >
              <label class="label">{{ $t('relation_driver') }}</label>
              <div class="control">
                <div class="field has-addons">
                  <div class="control">
                    <div class="input">
                      {{ relation.driver && relation.driver.drivingLicenseDate | date }}
                    </div>
                  </div>
                  <div class="control">
                    <sqr-router-link
                      icon="edit"
                      tooltip="relation_driver_edit"
                      to-name="relation-driver"
                      :to-rid="relation.rid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">{{ $t('relation_general_actions') }}</label>
              <div class="buttons">
                <sqr-button
                  v-show="!relation.archived"
                  icon="box"
                  :label="$t('relation_archive')"
                  color="red"
                  @click="archiveSet(true)"
                  :is-loading="archiving"
                />
                <sqr-button
                  v-show="relation.archived"
                  icon="box-open"
                  :label="$t('relation_unarchive')"
                  color="blue"
                  @click="archiveSet(false)"
                  :is-loading="archiving"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputText from '@/sqrd/SqrInputText';

import RelationNamePart from '@/components/RelationNamePart';
import RelationAddressPart from '@/components/RelationAddressPart';
import RelationCoordinatesPart from '@/components/RelationCoordinatesPart';
import RelationManagementPart from '@/components/RelationManagementPart';

import date from '@/filters/date';

export default {
  name: 'RelationGeneral',
  components: {
    SqrButton,
    SqrInputText,
    RelationNamePart,
    RelationAddressPart,
    RelationCoordinatesPart,
    RelationManagementPart,
  },
  filters: { date },
  props: {
    bid: String,
    rid: String,
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', ['loading', 'loadError']),
    ...mapGetters('relation', { relation: 'record' }),
    // ...mapGetters('policies', {policiesError: 'error'}),
    // ...mapGetters('prospection', ['targets']),
    title() {
      if (!this.relation) return '';
      return this.$t(
        'relation_title',
        Object.assign({}, this.relation, {
          status:
            this.relation.status &&
            this.$t('relation_status_' + this.relation.status),
        })
      );
    },
    subtitle() {
      if (!this.relation || !this.relation.birthDate) return '';
      return this.$t('relation_subtitle', {
        birthDate: date(this.relation.birthDate),
      });
    },
  },
  data() {
    return {
      archiving: false,
    };
  },
  mounted() {
    this.reload();
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  methods: {
    ...mapActions('relation', ['sub', 'unsub', 'fieldSet', 'update', 'save']),
    ...mapActions('relationsIndex', ['reindex']),
    reload() {
      this.sub({ path: ['brokers', this.bid], id: this.rid });
    },
    async archiveSet(archived = true) {
      try {
        this.archiving = true;
        await this.fieldSet({ field: 'archived', value: archived });
        const doc = this.relation;
        await this.reindex({ bid: this.bid, id: this.rid, doc });
      } catch (error) {
        console.log(error);
      } finally {
        this.archiving = false;
      }
    },
  },
};
</script>
