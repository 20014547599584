<template functional>
  <div class="bg-white shadow rounded p-4 mb-4" v-if="props.relation">
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.assistant">
      <div class="">{{ parent.$t('relation_assistant') }}</div>
      <div class="col-span-2 font-medium">{{ props.relation.assistant }}</div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.manager">
      <div class="">{{ parent.$t('relation_manager') }}</div>
      <div class="col-span-2 font-medium">{{ props.relation.manager }}</div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.intermediary">
      <div class="">{{ parent.$t('relation_intermediary') }}</div>
      <div class="col-span-2 font-medium">
        {{ props.relation.intermediary }}
      </div>
    </div>
    <router-link
      :to="{
        name: 'relation-management',
        params: { bid: props.bid, rid: props.rid },
      }"
    >
      <sqr-button
        icon="edit"
        :label="parent.$t('relation_management_edit')"
        color="white"
      />
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
th {
  font-weight: 500;
}
</style>

<script>
import SqrButton from '@/sqrd-ui/SqrButton';
export default {
  name: 'RelationManagementPart',
  components: { SqrButton },
  props: {
    relation: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
table.table th {
  padding-left: 0;
}
table.table {
  margin-bottom: 0;
}
</style>
