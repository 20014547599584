<template functional>
  <div class="bg-white rounded shadow p-4 mb-4" v-if="props.relation">
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.company">
      <div class="">{{ parent.$t('relation_company') }}</div>
      <div class="col-span-2 font-medium">{{ props.relation.company }}</div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.name">
      <div class="">{{ parent.$t('relation_name') }}</div>
      <div class="col-span-2 font-medium">{{ props.relation.name }}</div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.birthDate">
      <div class="">{{ parent.$t('relation_birth_date') }}</div>
      <div class="col-span-2 font-medium">
        {{ props.relation.birthDate | date }}
      </div>
    </div>
    <div class="grid grid-cols-3 mb-2" v-if="props.relation.nationality">
      <div class="">{{ parent.$t('relation_nationality') }}</div>
      <div class="col-span-2 font-medium">{{ props.relation.nationality }}</div>
    </div>
    <router-link
      :to="{
        name: 'relation-name',
        params: { bid: props.bid, rid: props.rid },
      }"
    >
      <sqr-button icon="edit" label="Modifier" color="white" />
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
th {
  font-weight: 500;
}
</style>

<script>
import date from '@/filters/date';

export default {
  name: 'RelationBasePart',
  props: {
    bid: { type: String, required: true },
    rid: { type: String, required: true },
    relation: { type: Object, required: true },
  },
  filters: {
    date,
  },
};
</script>

<style lang="scss" scoped>
table.table th {
  padding-left: 0;
}
table.table {
  margin-bottom: 0;
}
</style>
